import React, { useRef } from "react";
import { useFrame, useLoader, useThree } from "@react-three/fiber";
import {
  LinearFilter,
  NearestFilter,
  NoColorSpace,
  TextureLoader,
  Vector3,
} from "three";
import { Plane } from "@react-three/drei";

export function BackgroundImage({
  src = null,
  alphaSrc = null,
  depthSrc = null,
  heatmapSrc = null,
  maskSrc = null,
  showMask = true,
  shade = null,
  shadeColor = "0x000000",
  renderHeight = null,
  distanceToCamera = 10,
  renderCamera,
  interpolate = false,
  useAlpha = true,
}) {
  const heatmapRef = useRef();
  const shadeRef = useRef();
  const planeRef = useRef();

  const { camera } = useThree();

  useFrame(({}) => {
    if (!planeRef.current) return;

    // if (!src || !depthSrc) return;
    if (!src) return;
    const plane = planeRef.current;
    // this fov is wrong.
    const h = 2 * Math.tan((camera.fov / 360) * Math.PI) * distanceToCamera;
    const w = camera.aspect * h;

    plane.scale.set(w, h, 1);
    const dirVec = new Vector3(0, 0, -1)
      .applyEuler(camera.rotation)
      .normalize();
    plane.position
      .copy(camera.position)
      .addScaledVector(dirVec, distanceToCamera);
    plane.lookAt(camera.position);

    if (!!heatmapRef.current) {
      const heatmap = heatmapRef.current;
      heatmap.scale.set(w, h, 1);
      heatmap.position
        .copy(camera.position)
        .addScaledVector(dirVec, distanceToCamera - 0.002);
      heatmap.lookAt(camera.position);
    }
    if (!!shadeRef.current) {
      const shade = shadeRef.current;
      shade.scale.set(w, h, 1);
      shade.position
        .copy(camera.position)
        .addScaledVector(dirVec, distanceToCamera - 0.001);
      shade.lookAt(camera.position);
    }
  });

  const rgbTexture = src ? useLoader(TextureLoader, src) : null;
  const alphaTexture =
    src && useAlpha ? useLoader(TextureLoader, alphaSrc) : null;
  const depthTexture = depthSrc ? useLoader(TextureLoader, depthSrc) : null;
  const heatmapTexture = heatmapSrc
    ? useLoader(TextureLoader, heatmapSrc)
    : null;
  const maskTexture = maskSrc ? useLoader(TextureLoader, maskSrc) : null;

  if (interpolate) {
    if (rgbTexture) {
      rgbTexture.minFilter = LinearFilter;
      rgbTexture.magFilter = LinearFilter;
      rgbTexture.colorSpace = NoColorSpace;
    }

    if (heatmapTexture) {
      heatmapTexture.minFilter = LinearFilter;
      heatmapTexture.magFilter = LinearFilter;
      heatmapTexture.colorSpace = NoColorSpace;
    }
  } else {
    if (rgbTexture) {
      rgbTexture.minFilter = NearestFilter;
      rgbTexture.magFilter = NearestFilter;
      rgbTexture.colorSpace = NoColorSpace;
    }

    if (heatmapTexture) {
      heatmapTexture.minFilter = NearestFilter;
      heatmapTexture.magFilter = NearestFilter;
      heatmapTexture.colorSpace = NoColorSpace;
    }
  }

  if (maskTexture) {
    maskTexture.minFilter = NearestFilter;
    maskTexture.magFilter = NearestFilter;
    maskTexture.colorSpace = NoColorSpace;
  }

  return (
    <>
      {depthSrc ? (
        <Plane
          key="rgb"
          ref={planeRef} // args={[1, 1]}
          args={[1, 1, depthTexture.image.width, depthTexture.image.height]}
          scale={[camera.aspect, 1, 1]}
        >
          <meshBasicMaterial
            attach="material"
            map={rgbTexture}
            displacementMap={depthTexture}
            displacementScale={-10}
          />
        </Plane>
      ) : (
        <Plane
          key="rgb"
          ref={planeRef}
          args={[1, 1]}
          scale={[camera.aspect, 1, 1]}
        >
          <meshBasicMaterial
            attach="material"
            map={rgbTexture}
            alphaMap={alphaTexture}
            transparent
          />
        </Plane>
      )}
      {heatmapTexture && maskTexture && showMask
        ? [
            <Plane
              key="shade"
              ref={shadeRef}
              args={[1, 1]}
              scale={[camera.aspect, 1, 1]}
            >
              <meshBasicMaterial
                attach="material"
                transparent
                opacity={shade}
                color={shadeColor}
              />
            </Plane>,
            <Plane
              key="mask"
              ref={heatmapRef}
              args={[1, 1]}
              scale={[camera.aspect, 1, 1]}
            >
              <meshBasicMaterial
                attach="material"
                map={heatmapTexture}
                alphaMap={maskTexture}
                transparent
                opacity={1}
              />
            </Plane>,
          ]
        : null}
    </>
  );
}

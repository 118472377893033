import React, {useCallback, useEffect, useMemo, useState} from "react";
import useStateRef from "react-usestateref";
import {parse} from "query-string";
// import {Plane, Text} from "@react-three/drei";
import {document} from "browser-monads";
import ThreeScene from "../../tassa/_components/_three_components/_three_scene";
import {Hydrate} from "../../tassa/_components/_index";
import {button, buttonGroup, folder, Leva, useControls} from "leva";
import useFetch from "use-http";
import yaml from "js-yaml";
import {CustomLeva, list2menu, value2obj,} from "../../tassa/_components/_three_components/_leva_helper";
import useWebSocket from "react-use-websocket";
import {findByKey} from "../../tassa/_util";
import {useGamepads} from "react-gamepads";
import {BackgroundImage} from "../../tassa/_components/_three_components/_background_image";
// import {TextureLoader} from "three";

// The dataloader component hides the list of children from the outer scope.
// this means we can not direcltly show the
function makeProps(props) {
  const toProps = (data) => {
    const children = (data || [])
      // .filter((v, i) => !hide[i])
      .map(({ key, ...child }) => (
        <Hydrate key={key} _key={key} {...props} {...child} />
      ));
    return { children };
  };
  return toProps;
}

function base64ToImage(base64) {
  var img = new Image();
  img.src = "data:image/jpeg;base64," + base64;
  return img;
}

function base64ToUri(base64, mtype = "image/jpeg") {
  return `data:${mtype};base64,${base64}`;
}

function base64ToCanvas(base64, canvas) {
  var img = new Image();
  img.onload = function () {
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    ctx.globalCompositeOperation = "destination-over";
  };
  img.src = "data:image/png;base64," + base64;
}

export default function Three({ children: _, ..._props }) {
  const queries = useMemo(() => parse(document.location.search), []);
  const paramsRot = queries.rotation
    ?.split(",")
    .filter((u) => u && u === u)
    .map(parseFloat);
  const paramsPos = queries.position
    ?.split(",")
    .filter((u) => u && u === u)
    .map(parseFloat);

  const defaultScale = parseFloat(queries.scale) || 1;

  // for server-side renering
  if (typeof window === "undefined")
    return <div>threejs view server-side rendering</div>;

  const [scene, setScene, sceneRef] = useStateRef({
    children: [],
    rawChildren: [],
  });
  // const setScene = (s) => {
  //   console.log("setScene", s, sceneRef.current);
  //   return _setScene(s)
  // }
  const [menu, setMenu] = useState({});
  const scene_uri = (queries.scene || "").toLowerCase();

  // const [bgImage, setBackgroundImage] = useState(null);
  const [rgbURI, setRGB] = useState(null);
  const [alphaURI, setAlphaMap] = useState(null);
  const [depthURI, setDepth] = useState(null);
  const [heatmapURI, setHeatmap] = useState(null);
  const [maskURI, setMask] = useState(null);

  const [renderCamera, setRenderCamera] = useState({});
  const [isConnected, setIsConnected, connectRef] = useStateRef(false);
  const [connectWS, setConnectWS] = useState(true);
  const [reconnect, allowReconnect] = useState(true);

  const { response } = useFetch(queries.scene, [queries.scene]);

  useGamepads((gamepads) => {
    const { axes, buttons, connected, id, index, mapping, timestamp } =
      gamepads[0];
    sendMsg({
      etype: "GAMEPADS",
      value: { axes, buttons: buttons.map((b) => b.value) },
    });
  });
  useEffect(() => {
    // do not change the scene using Fetch unless queries.scene is set.
    if (!queries.scene) return;
    let __scene;
    if (!response || !response.ok) __scene = { children: [] };
    else if (scene_uri.endsWith(".json")) {
      __scene = response.data;
    } else if (scene_uri.endsWith(".yml") || scene_uri.endsWith(".yaml")) {
      __scene = yaml.load(response.data);
    } else if (queries.scene) {
      // try {
      //   const jsonStr = atob(queries.scene);
      //   __scene = JSON.parse(jsonStr)
      // } catch (e) {
      //   console.log("Failed to parse scene", e);
      //   __scene = {children: []};
      // }
      console.log("not implemented");
    } else {
      __scene = { children: [] };
    }
    if (typeof __scene.children === "undefined")
      __scene = { children: __scene };
    setScene(__scene);
    setMenu(list2menu(__scene.children, false));
  }, [scene_uri, response.data]);

  const [
    {
      "Socket URI": socketURI,
      rotation,
      position,
      scale,
      fov,
      "Near (cm)": near_cm,
      "Far (m)": far_m,
      "Fit bounds": fit,
      // inhereted to all children
      pointSize,
      // marker params
      enableMarker,
      markerRadius,
      markerAverage,
      background,
      progressive,
      renderHeight,
      showRender,
      sceneBox,
      useAlpha,
      useAABB,
      aabbMin,
      aabbMax,
      channel,
      heatmap,
      heatmapColormap,
      showMask,
      shade,
      alphaThreshold,
      shadeColor,
      query,
      negativeQueries,
      normalize,
      gain,
      clip,
      temperature,
      colormap,
      showNodes,
      grid,
      ...layerConfigs
    },
    setLeva,
  ] = useControls(
    function () {
      return {
        "Socket URI": { value: queries.ws || "ws://localhost:8080", order: 0 },
        Reconnect: button(
          (get) => {
            setConnectWS(false);
            allowReconnect(false);
            setTimeout(() => {
              setConnectWS(true);
            }, 100);
          },
          {
            disabled: !reconnect,
            label: isConnected ? "Reconnect" : "Disconnected",
            order: -1,
          }
        ),
        "Camera Control": folder(
          {
            fov: {
              label: "Fov°",
              value: parseFloat(queries.fov) || 75,
              min: 5,
              max: 89,
              step: 0.5,
            },
            "Near (cm)": {
              value: parseFloat(queries.near) || 1,
              min: 0.1,
              max: 1000,
              step: 0.1,
            },
            "Far (m)": {
              value: parseFloat(queries.far) || defaultScale * 20,
              min: 0.1,
              max: 1000,
              step: 0.1,
            },
            "Fit bounds": queries.fit === "true",
          },
          { collapsed: true }
        ),
        // "Take Screenshot": button(() => {}, {disabled: true}),
        show_cameras: { value: false, label: "Show Cameras" },
        background: {
          value: queries.background || "#151822",
          label: "Background Color",
        },
        Scene: folder({
          rotation: {
            value:
              paramsRot?.length === 3
                ? { x: paramsRot[0], y: paramsRot[1], z: paramsRot[2] }
                : { x: 0, y: 0, z: 0 },
            order: 1,
            lock: true,
          },
          position: {
            value:
              paramsPos?.length === 3
                ? { x: paramsPos[0], y: paramsPos[1], z: paramsPos[2] }
                : { x: 0, y: 0, z: 0 },
            order: -1,
            lock: true,
          },
          scale: {
            value: defaultScale,
            min: 0.0001,
            step: 0.01,
            label: "Scale",
            order: 2,
            pad: 4,
          },
          sceneBox: { value: true, label: "Scene Box", order: 3 },
          useAlpha: { value: false, label: "Use Alpha", order: 4 },
          useAABB: { value: false, label: "Use AABB", order: 5 },
          aabbMin: {
            value: { x: -1, y: -1, z: -1 },
            label: "Min",
            step: 0.001,
            pad: 4,
            lock: true,
            order: 6,
          },
          aabbMax: {
            value: { x: 1, y: 1, z: 1 },
            label: "Max",
            step: 0.001,
            pad: 4,
            lock: true,
            order: 7,
          },
        }),
        Render: folder(
          {
            channel: {
              value: "rgb",
              options: ["rgb", "depth", "features", "accumulation"],
              label: "Channel",
            },
            showRender: { value: true, label: "Show Rendering" },
            colormap: {
              value: "viridis",
              options: [
                "PCA",
                "Accent",
                "Accent_r",
                "Blues",
                "Blues_r",
                "BrBG",
                "BrBG_r",
                "BuGn",
                "BuGn_r",
                "BuPu",
                "BuPu_r",
                "CMRmap",
                "CMRmap_r",
                "Dark2",
                "Dark2_r",
                "GnBu",
                "GnBu_r",
                "Greens",
                "Greens_r",
                "Greys",
                "Greys_r",
                "OrRd",
                "OrRd_r",
                "Oranges",
                "Oranges_r",
                "PRGn",
                "PRGn_r",
                "Paired",
                "Paired_r",
                "Pastel1",
                "Pastel1_r",
                "Pastel2",
                "Pastel2_r",
                "PiYG",
                "PiYG_r",
                "PuBu",
                "PuBuGn",
                "PuBuGn_r",
                "PuBu_r",
                "PuOr",
                "PuOr_r",
                "PuRd",
                "PuRd_r",
                "Purples",
                "Purples_r",
                "RdBu",
                "RdBu_r",
                "RdGy",
                "RdGy_r",
                "RdPu",
                "RdPu_r",
                "RdYlBu",
                "RdYlBu_r",
                "RdYlGn",
                "RdYlGn_r",
                "Reds",
                "Reds_r",
                "Set1",
                "Set1_r",
                "Set2",
                "Set2_r",
                "Set3",
                "Set3_r",
                "Spectral",
                "Spectral_r",
                "Wistia",
                "Wistia_r",
                "YlGn",
                "YlGnBu",
                "YlGnBu_r",
                "YlGn_r",
                "YlOrBr",
                "YlOrBr_r",
                "YlOrRd",
                "YlOrRd_r",
                "afmhot",
                "afmhot_r",
                "autumn",
                "autumn_r",
                "binary",
                "binary_r",
                "bone",
                "bone_r",
                "brg",
                "brg_r",
                "bwr",
                "bwr_r",
                "cividis",
                "cividis_r",
                "cool",
                "cool_r",
                "coolwarm",
                "coolwarm_r",
                "copper",
                "copper_r",
                "cubehelix",
                "cubehelix_r",
                "flag",
                "flag_r",
                "gist_earth",
                "gist_earth_r",
                "gist_gray",
                "gist_gray_r",
                "gist_heat",
                "gist_heat_r",
                "gist_ncar",
                "gist_ncar_r",
                "gist_rainbow",
                "gist_rainbow_r",
                "gist_stern",
                "gist_stern_r",
                "gist_yarg",
                "gist_yarg_r",
                "gnuplot",
                "gnuplot2",
                "gnuplot2_r",
                "gnuplot_r",
                "gray",
                "gray_r",
                "hot",
                "hot_r",
                "hsv",
                "hsv_r",
                "inferno",
                "inferno_r",
                "jet",
                "jet_r",
                "magma",
                "magma_r",
                "nipy_spectral",
                "nipy_spectral_r",
                "ocean",
                "ocean_r",
                "pink",
                "pink_r",
                "plasma",
                "plasma_r",
                "prism",
                "prism_r",
                "rainbow",
                "rainbow_r",
                "seismic",
                "seismic_r",
                "spring",
                "spring_r",
                "summer",
                "summer_r",
                "tab10",
                "tab10_r",
                "tab20",
                "tab20_r",
                "tab20b",
                "tab20b_r",
                "tab20c",
                "tab20c_r",
                "terrain",
                "terrain_r",
                "turbo",
                "turbo_r",
                "twilight",
                "twilight_r",
                "twilight_shifted",
                "twilight_shifted_r",
                "viridis",
                "viridis_r",
                "winter",
                "winter_r",
              ],
              label: "Color Map",
            },
            "Refresh PCA Map": button(() =>
              sendMsg({ etype: "RESET_PCA_MAP", value: {} })
            ),
            normalize: { value: false, label: "Normalize" },
            clip: { value: { x: 0, y: 20 }, step: 0.01, label: "Clip Value" },
            gain: { value: 1, step: 0.01, min: 0.001, label: "Gain" },
            renderHeight: {
              value: 768,
              min: 1,
              max: 2304,
              step: 1,
              pad: 1,
              label: "Height",
            },
            "  ": buttonGroup({
              label: "Res Presets",
              opts: {
                384: () => setLeva({ renderHeight: 384 }),
                480: () => setLeva({ renderHeight: 480 }),
                768: () => setLeva({ renderHeight: 768 }),
                960: () => setLeva({ renderHeight: 960 }),
                1536: () => setLeva({ renderHeight: 1536 }),
              },
            }),
            progressive: {
              value: -2,
              min: -3,
              max: 0,
              step: 0.5,
              label: "Progressive Scale",
            },
            " ": buttonGroup({
              label: "Preset",
              opts: {
                "-3": () => setLeva({ progressive: -3 }),
                "-2.5": () => setLeva({ progressive: -2.5 }),
                "-2": () => setLeva({ progressive: -2 }),
                "-1.5": () => setLeva({ progressive: -1.5 }),
                "-1": () => setLeva({ progressive: -1 }),
                "-1/2": () => setLeva({ progressive: -0.5 }),
                "/": () => setLeva({ progressive: 0 }),
              },
            }),
            alphaThreshold: {
              value: 0.0,
              min: 0,
              max: 1,
              step: 0.001,
              pad: 4,
              label: "ɑ Threshold",
            },
          },
          { label: "NeRF", collapsed: true }
        ),
        LANGUAGE: folder(
          {
            heatmap: {
              value: null,
              default: null,
              options: [null, "features"],
              label: "Heat Map",
            },
            query: { value: "", label: "Text Query" },
            negativeQueries: { value: "", rows: 2, label: "Negatives" },
            showMask: { value: true, label: "Show Mask" },
            heatmapColormap: {
              value: null,
              options: [
                null,
                "Accent",
                "Accent_r",
                "Blues",
                "Blues_r",
                "BrBG",
                "BrBG_r",
                "BuGn",
                "BuGn_r",
                "BuPu",
                "BuPu_r",
                "CMRmap",
                "CMRmap_r",
                "Dark2",
                "Dark2_r",
                "GnBu",
                "GnBu_r",
                "Greens",
                "Greens_r",
                "Greys",
                "Greys_r",
                "OrRd",
                "OrRd_r",
                "Oranges",
                "Oranges_r",
                "PRGn",
                "PRGn_r",
                "Paired",
                "Paired_r",
                "Pastel1",
                "Pastel1_r",
                "Pastel2",
                "Pastel2_r",
                "PiYG",
                "PiYG_r",
                "PuBu",
                "PuBuGn",
                "PuBuGn_r",
                "PuBu_r",
                "PuOr",
                "PuOr_r",
                "PuRd",
                "PuRd_r",
                "Purples",
                "Purples_r",
                "RdBu",
                "RdBu_r",
                "RdGy",
                "RdGy_r",
                "RdPu",
                "RdPu_r",
                "RdYlBu",
                "RdYlBu_r",
                "RdYlGn",
                "RdYlGn_r",
                "Reds",
                "Reds_r",
                "Set1",
                "Set1_r",
                "Set2",
                "Set2_r",
                "Set3",
                "Set3_r",
                "Spectral",
                "Spectral_r",
                "Wistia",
                "Wistia_r",
                "YlGn",
                "YlGnBu",
                "YlGnBu_r",
                "YlGn_r",
                "YlOrBr",
                "YlOrBr_r",
                "YlOrRd",
                "YlOrRd_r",
                "afmhot",
                "afmhot_r",
                "autumn",
                "autumn_r",
                "binary",
                "binary_r",
                "bone",
                "bone_r",
                "brg",
                "brg_r",
                "bwr",
                "bwr_r",
                "cividis",
                "cividis_r",
                "cool",
                "cool_r",
                "coolwarm",
                "coolwarm_r",
                "copper",
                "copper_r",
                "cubehelix",
                "cubehelix_r",
                "flag",
                "flag_r",
                "gist_earth",
                "gist_earth_r",
                "gist_gray",
                "gist_gray_r",
                "gist_heat",
                "gist_heat_r",
                "gist_ncar",
                "gist_ncar_r",
                "gist_rainbow",
                "gist_rainbow_r",
                "gist_stern",
                "gist_stern_r",
                "gist_yarg",
                "gist_yarg_r",
                "gnuplot",
                "gnuplot2",
                "gnuplot2_r",
                "gnuplot_r",
                "gray",
                "gray_r",
                "hot",
                "hot_r",
                "hsv",
                "hsv_r",
                "inferno",
                "inferno_r",
                "jet",
                "jet_r",
                "magma",
                "magma_r",
                "nipy_spectral",
                "nipy_spectral_r",
                "ocean",
                "ocean_r",
                "pink",
                "pink_r",
                "plasma",
                "plasma_r",
                "prism",
                "prism_r",
                "rainbow",
                "rainbow_r",
                "seismic",
                "seismic_r",
                "spring",
                "spring_r",
                "summer",
                "summer_r",
                "tab10",
                "tab10_r",
                "tab20",
                "tab20_r",
                "tab20b",
                "tab20b_r",
                "tab20c",
                "tab20c_r",
                "terrain",
                "terrain_r",
                "turbo",
                "turbo_r",
                "twilight",
                "twilight_r",
                "twilight_shifted",
                "twilight_shifted_r",
                "viridis",
                "viridis_r",
                "winter",
                "winter_r",
              ],
              label: "Color Map",
            },
            temperature: {
              value: 0.1,
              min: 0.0000001,
              step: 0.00001,
              pad: 5,
              label: "Temperature",
            },
            shade: { value: 0.3, step: 0.01, min: 0.0, max: 1, label: "Shade" },
            shadeColor: { value: "#000000", label: "Shade Color" },
          },
          { collapsed: true }
        ),
        custom: folder(
          {
            README: {
              value: "Use GUI API to Create Menu Items",
              editable: false,
            },
          },
          { label: "Custom Controls" }
        ),
        Pointers: folder(
          {
            enableMarker: { value: false, label: "Enable" },
            markerSize: {
              value: 10,
              min: 0.1,
              max: 100,
              step: 0.1,
              pad: 1,
              label: "Pointer Size",
            },
            markerAvg: { value: 5, min: 0, max: 20, step: 1, label: "Average" },
          },
          { collapsed: true }
        ),
        "Scene Graph": folder(
          {
            show_this: false,
            showNodes: { value: true, label: "Show Nodes" },
            pointSize: {
              value: queries.pointSize ? parseFloat(queries.pointlSize) : 1.5,
              min: 0.1,
              max: 100,
              step: 0.1,
              pad: 1,
              label: "PC Point Size",
            },
            ...menu,
          },
          { collapsed: true, order: -2 }
        ),
      };
    },
    [scene_uri, menu, reconnect, isConnected]
  );

  // const [t, setRenderTTL, renderTTLRef] = useStateRef(0)
  const [lastCameraMove, setLastCameraMove, cameraMoveRef] = useStateRef({});

  useEffect(() => {
    if (lastCameraMove) sendMsg(lastCameraMove);
  }, [
    channel,
    colormap,
    normalize,
    clip,
    gain,
    heatmap,
    temperature,
    query,
    negativeQueries,
  ]);

  function onOpen() {
    sendMsg({ etype: "INIT" });
    setIsConnected(true);
    allowReconnect(true);
  }

  function onClose() {
    setIsConnected(false);
    allowReconnect(false);
  }

  function onError() {
    setIsConnected(false);
    allowReconnect(false);
  }

  function onReconnectStop() {
    allowReconnect(true);
  }

  const [customLeva, setCustomLeva] = useState({});

  const onMessage = useCallback(
    ({ data: message, isTrusted }) => {
      let etype, eventData;
      try {
        ({ etype, data: eventData } = JSON.parse(message));
      } catch (e) {
        console.log("message:", message);
      }
      if (etype === "SET") {
        // the top level is a dummy node
        setScene(eventData);
      } else if (etype === "SET_LEVA") {
        setCustomLeva(eventData);
      } else if (etype === "UPDATE") {
        let dirty = false;
        for (const { key, ...props } of eventData) {
          const node = findByKey(sceneRef.current, key, [
            "rawChildren",
            "htmlChildren",
          ]);
          if (node) {
            Object.assign(node, props);
            dirty = true;
          } else {
            console.log("node not found", key, sceneRef.current);
          }
        }
        if (dirty) setScene(sceneRef.current);
      } else if (etype === "END") {
        setReconnect(false);
      } else if (etype === "RENDER") {
        if (eventData.rgb) {
          const uri = base64ToUri(eventData.rgb, "image/jpeg");
          setRGB(uri);
        }
        if (eventData.alpha) {
          const uri = base64ToUri(eventData.alpha, "image/jpeg");
          setAlphaMap(uri);
        }
        if (eventData.depth) {
          const uri = base64ToUri(eventData.depth, "image/png");
          setDepth(uri);
        }
        if (eventData.heatmap) {
          const uri = base64ToUri(eventData.heatmap, "image/jpeg");
          console.log(uri);
          setHeatmap(uri);
        } else {
          setHeatmap(null);
        }
        if (eventData.mask) {
          const uri = base64ToUri(eventData.mask);
          console.log(uri);
          setMask(uri);
        } else {
          setMask(null);
        }
        if (eventData.camera) {
          console.log("remove this, deprecated");
          // setRenderCamera({
          //   aspect: eventData.camera.aspect,
          //   fov: eventData.camera.fov,
          //   scale,
          //   position: new Vector3(...eventData.camera.position),
          //   rotation: new Euler(...eventData.camera.rotation),
          // });
        }
      } else {
        console.log(">>>>>", message);
      }
    },
    [socketURI]
  );

  const [$, setReconnect, shouldReconnect] = useStateRef(true);

  const return_object = useWebSocket(
    socketURI,
    {
      onMessage,
      onOpen,
      onError,
      onClose,
      onReconnectStop,
      share: true,
      retryOnError: true,
      shouldReconnect: function () {
        return shouldReconnect.current;
      },
      reconnectAttempts: 3,
      reconnectInterval: 1000,
    },
    socketURI && connectWS
  );
  // console.log(return_object);
  const { sendJsonMessage, getWebSocket, readyState } = return_object;

  const sendMsg = useCallback(
    function (event) {
      // race condition, avoid without testing.
      // if (!isConnected) return;

      // normalize the event object
      event.value = event.value || {};

      if (event.etype === "SNAPSHOT") {
        const { etype, value, ...rest } = cameraMoveRef.current;
        sendJsonMessage({
          ...event,
          value: { ...event.value, ...value },
          ...rest,
        });
      } else if (event.etype === "RESET_PCA_MAP") {
        const { etype, value, ...rest } = cameraMoveRef.current;
        sendJsonMessage({
          ...event,
          value: { ...event.value, ...value },
          ...rest,
        });
      } else if (event.etype === "CAMERA_MOVE") {
        const { etype, value, ...rest } = event;

        value.camera.height = renderHeight;
        value.camera.width = value.camera.height * value.camera.aspect;

        value.progressive = Math.pow(2, progressive);
        // value.channels = [channel, 'depth']
        value.channels = [channel];
        value.colormap = colormap;
        value.normalize = normalize;
        value.clip = [clip.x, clip.y];
        value.gain = gain;
        value.heatmap = heatmap;
        value.heatmap_colormap = heatmapColormap;
        value.alpha_threshold = alphaThreshold;
        value.query = query;
        value.temperature = temperature;
        value.use_aabb = useAABB;
        // used to set the background in aabb rendering mode
        value.bg_color = background;
        value.aabb_min = [aabbMin.x, aabbMin.y, aabbMin.z];
        value.aabb_max = [aabbMax.x, aabbMax.y, aabbMax.z];
        // use python convention
        if (negativeQueries) {
          value.negative_queries = (negativeQueries || "").split("\n");
        }
        setLastCameraMove({ etype, value, ...rest });
        sendJsonMessage({ etype, value, ...rest });
      } else {
        // catch all. Needed for INIT event handling
        sendJsonMessage(event);
      }

      //   tood: add channel
    },
    [
      socketURI,
      renderHeight,
      progressive,
      channel,
      colormap,
      normalize,
      clip,
      gain,
      heatmap,
      heatmapColormap,
      temperature,
      query,
      negativeQueries,
      useAABB,
      aabbMin,
      aabbMax,
      background,
      alphaThreshold,
      readyState,
    ]
  );

  let filteredNodes = useMemo(
    () => value2obj(scene.children, layerConfigs),
    [scene, layerConfigs]
  );

  const [world, setWorld] = useState({
    scale,
    position: [0, 0, 0],
    rotation: [0, 0, 0],
  });

  useEffect(() => {
    setWorld({
      scale,
      position: [position.x, position.y, position.z],
      rotation: [
        (rotation.x / 360) * 2 * Math.PI,
        (rotation.y / 360) * 2 * Math.PI,
        (rotation.z / 360) * 2 * Math.PI,
      ],
    });
  }, [position, rotation, scale]);

  const {
    children: sceneChildren,
    htmlChildren: sceneHtmlChildren,
    rawChildren: sceneRawChildren,
    ..._scene
  } = scene;

  const rest = {
    // ...attr,
    fit,
    fov,
    near: near_cm / 100,
    far: far_m,
    ...world,
    markerRadius,
    markerAverage,
    enableMarker,
    background,
    sceneBox,
    sceneBoxMin: [aabbMin.x, aabbMin.y, aabbMin.z],
    sceneBoxMax: [aabbMax.x, aabbMax.y, aabbMax.z],
    sendMsg,
    initCameraPosition: queries.initCameraPosition?.split(",").map(parseFloat),
    ..._props,
    // add the scene params here to allow programmatic override
    ..._scene,
  };

  const toProps = useCallback(makeProps({ size: pointSize / 1000, sendMsg }), [
    pointSize,
    sendMsg,
  ]);
  const children = showNodes && scene ? toProps(filteredNodes).children : [];
  const rawChildren = sceneRawChildren
    ? toProps(sceneRawChildren).children
    : [];
  const htmlChildren = sceneHtmlChildren
    ? toProps(sceneHtmlChildren).children
    : [];

  let bgi;
  // if (showRender && rgbURI && depthURI) {
  if (showRender && rgbURI) {
    bgi = (
      <BackgroundImage
        src={rgbURI}
        alphaSrc={alphaURI}
        // depthSrc={depthURI}
        heatmapSrc={heatmapURI}
        maskSrc={maskURI}
        showMask={showMask}
        shade={shade}
        shadeColor={shadeColor}
        renderCamera={renderCamera}
        renderHeight={renderHeight}
        distanceToCamera={1}
        interpolate={false}
        useAlpha={useAlpha}
      />
    );
  }

  return (
    <div>
      <ThreeScene
        backgroundChildren={bgi}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: 10,
        }}
        rawChildren={rawChildren}
        htmlChildren={htmlChildren}
        {...rest}
      >
        {customLeva ? (
          <CustomLeva sendMsg={sendMsg} config={customLeva} />
        ) : null}
        {children}
      </ThreeScene>
      <Leva
        collapsed={queries.collapseMenu === "true"}
        style={{ zIndex: 10000000 }}
      />
    </div>
  );
}
